import * as React from "react";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import PeopleIcon from "@mui/icons-material/People";
import HomeIcon from "@mui/icons-material/Home";
import TokenIcon from "@mui/icons-material/CurrencyBitcoin";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  PieCell,
  Pie,
  Cell,
} from "recharts";
import BarChartIcon from "@mui/icons-material/BarChart";
import PieChartIcon from "@mui/icons-material/PieChart";
import StarRateIcon from "@mui/icons-material/StarRate";
import EuroIcon from "@mui/icons-material/Euro";
import axios from "axios";
import ListImmobileAgenzia from "./Dashboard/ListImmobileOperatori";
import ClientiOperatori from "./Adminlist/User/ClientiOperatori";

function Dashboard() {
  const general = useSelector((state) => state.general);
  const [jsonData, setJsonData] = React.useState([]);
  const [data, setData] = React.useState({
    totalOnboardingAuctions: 0,
    totalActiveAuctions: 0,
    totalAste: 0,
    totalUsers: 0,
    activeUsers: 0,
    tokensSold: 0,
    revenueInEuro: 0,
  });

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const storedToken = localStorage.getItem("token");

        if (!storedToken) {
          console.error("Token not available.");
          // Puoi gestire qui il caso in cui il token non sia disponibile
          return;
        }
        const headers = {
          Authorization: `Bearer ${storedToken}`,
        };

        const response = await axios.get(
          `${process.env.REACT_APP_APP_ENDPOINT}api/auth/getDashboardData`,
          { headers }
        );

        setJsonData(response.data);

        if (response.data) {
          setData({
            totalOnboardingAuctions: response.data.totalOnboardingAuctions,
            totalActiveAuctions: response.data.totalActiveAuctions,
            totalAste: response.data.totalAste,
            totalUsers: response.data.totalUsers,
            activeUsers: response.data.totalActiveUsers,
            tokensSold: response.data.totalTokens.original.saldoWallet,
            revenueInEuro: 0,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Puoi gestire qui l'errore in base alle tue esigenze
      }
    };

    fetchData();
  }, []);

  const COLORS = ["#0088FE", "#FF8042"];

  const PieChartAgenzieMasters = (
    <PieChart width={300} height={300}>
      <Pie
        dataKey="count"
        isAnimationActive={true}
        data={jsonData.userByRole || []}
        cx={140}
        cy={120}
        outerRadius={95}
        fill="#8884d8"
        label
      >
        {(jsonData.userByRole || []).map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend
        payload={(jsonData.userByRole || []).map((entry, index) => ({
          value: `${entry.role} (${entry.count})`,
          type: "square",
          id: entry.role,
          color: COLORS[index % COLORS.length],
        }))}
      />
    </PieChart>
  );

  const BarChartAgenzieToken = (
    <BarChart width={700} height={300} data={jsonData.immobiliPerCitta}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="citta" />
      <YAxis />
      <Tooltip />
      {/* <Legend /> */}
      <Bar dataKey="count" fill="#82ca9d" />
    </BarChart>
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Card sx={{ m: 1 }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Benvenuto,{" "}
              {general.username ? general.username : general.temporaryRole}
            </Typography>
            <Typography gutterBottom variant="span" component="span">
              Hai effettuato l'accesso come,{" "}
              {general.temporaryRole
                ? general.temporaryRole
                : general.temporaryRole}
            </Typography>
          </CardContent>

        </Card>
      </Grid>

      {general.temporaryRole == "Admin" && (
        <>
          <Grid item xs={12} md={4}>
            <Card sx={{ m: 1 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  <HomeIcon /> Aste
                </Typography>
                <Typography variant="body2">
                  Onboarding: {data.totalOnboardingAuctions}
                </Typography>
                <Typography variant="body2">
                  Attive: {data.totalActiveAuctions}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card sx={{ m: 1 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  <PeopleIcon /> Utenti
                </Typography>
                <Typography variant="body2">
                  Iscritti: {data.totalUsers}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card sx={{ m: 1, display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flexGrow: 1, widht: "100%" }}>
                <Typography variant="h5" component="div">
                  <StarRateIcon /> Quantità immobili per città
                </Typography>
                <div style={{ marginTop: "20px", padding: "10px" }}>
                  {BarChartAgenzieToken}
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card sx={{ m: 1, display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h5" component="div">
                  <PieChartIcon /> Panoramica utenti
                </Typography>
                <div style={{ marginTop: "20px", padding: "10px" }}>
                  {PieChartAgenzieMasters}
                </div>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
      {general.temporaryRole === "Agenzia" && (
        <>
          <Grid container sx={{ margin: 2 }}>
            <Grid item xs={12}>
              <ListImmobileAgenzia />
            </Grid>
          </Grid>
          <Grid container sx={{ margin: 2 }}>
            <Grid item xs={12}>
              <Card sx={{ m: 1 }}>
                <ClientiOperatori />
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Dashboard;
