import React from "react";
import {
    Card,
    CardContent,
    CardMedia,
    Typography,
    Grid,
    Skeleton,
    Box,
    Button,
} from "@mui/material";
import { styled } from "styled-components";
import { NewReleases as NewReleasesIcon, SearchOff as SearchOffIcon } from "@mui/icons-material";
import TempoScadenza from "components/icons/TempoScadenza";
import UltimaOfferta from "./UltimaOfferta";
import RilancioMinimo from "./RilancioMinimo";
import RilanciTotali from "components/icons/RilanciTotali";
import SuperficieIcon from "components/icons/Supercie";
import LocaliIcon from "components/icons/Locali";

const ColoredButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#66c9ba",
    color: "#ffffff",
    "&:hover": {
        backgroundColor: "transparent",
        color: "#66c9ba",
    },
}));

const AsteGrid = ({ cards, loadingCard, goToRoute }) => {



    return (
        <Grid item xs={12} container spacing={2} alignItems="center">
            {loadingCard ? (
                Array.from({ length: 6 }).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                        <Card>
                            <Skeleton
                                animation="wave"
                                variant="rectangular"
                                height={200}
                                sx={{
                                    borderRadius: "8px 8px 0 0",
                                    backgroundColor: "#e0e0e0",
                                }}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    <Skeleton animation="wave" height={20} width="80%" />
                                </Typography>
                                <Box display="flex" alignItems="center" mb={2}>
                                    <Box
                                        sx={{
                                            width: "20px",
                                            height: "20px",
                                            backgroundColor: "#e0e0e0",
                                            borderRadius: "50%",
                                            marginRight: "8px",
                                        }}
                                    />
                                    <Typography variant="body2" color="text.secondary">
                                        <Skeleton animation="wave" height={10} width="40%" />
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            ) : cards.length === 0 ? (
                // Se non ci sono risultati
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="300px"
                    sx={{
                        margin: "auto", // Centra il Box orizzontalmente
                        textAlign: "center", // Allinea il testo al centro
                    }}
                >
                    <SearchOffIcon sx={{ fontSize: 80, color: "#b0b0b0", mb: 2 }} />
                    <Typography variant="h6" color="textSecondary">
                        Nessun risultato trovato
                    </Typography>
                </Box>
            ) : (
                cards.map((card) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} key={card.id}>
                        <Card
                            sx={{
                                boxShadow: "2px 4px 11px rgba(0, 0, 0, 0.15)",
                                borderRadius: "10px",
                            }}
                        >
                            <div style={{ position: "relative" }}>
                                <CardMedia
                                    component="img"
                                    alt="Immagine dell'asta"
                                    image={
                                        card?.firstImage ||
                                        "https://placehold.co/1200x1080?text=Nessun+immagine"
                                    }
                                    sx={{
                                        objectFit: "cover",
                                        maxHeight: 180,
                                        filter: "drop-shadow(2px 4px 11px rgba(0, 0, 0, 0.15))",
                                        borderRadius: "10px 10px 0px 0px",
                                    }}
                                />
                                {card?.houseType && (
                                    <span
                                        style={{
                                            position: "absolute",
                                            top: 10,
                                            left: 10,
                                            background: "#66C9BA",
                                            color: "#01685D",
                                            borderRadius: 5,
                                            padding: "0px 8px 2px 8px",
                                        }}
                                    >
                                        {card?.houseType}
                                    </span>
                                )}
                                {card?.newConstruction == 1 && (
                                    <span
                                        style={{
                                            position: "absolute",
                                            display: "flex",
                                            top: 10,
                                            left: 140,
                                            color: "#ffffff",
                                            backgroundColor: "#66C9BA",
                                            borderRadius: "50%",
                                            padding: "0px",
                                        }}
                                    >
                                        <NewReleasesIcon fontSize="small" />
                                    </span>
                                )}
                                {card?.type && (
                                    <span
                                        style={{
                                            position: "absolute",
                                            top: 10,
                                            right: 10,
                                            background: "#d32f2f",
                                            borderRadius: 5,
                                            color: "white",
                                            padding: "2px 8px 2px 8px",
                                        }}
                                    >
                                        {card?.type}
                                    </span>
                                )}
                            </div>
                            <CardContent sx={{ px: 3 }}>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                    sx={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    <b>{card.title}</b>
                                </Typography>
                                <Box display="flex" alignItems="center" mb={2} sx={{ gap: 1.5 }}>
                                    <TempoScadenza />
                                    <Typography variant="body2" color="text.secondary">
                                        Scadenza: {card.duration}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" mb={2} sx={{ gap: 1.5 }}>
                                    <SuperficieIcon fillColor="#FF0000" width="20" height="20" />
                                    <Typography variant="body2" color="text.secondary">
                                        Superficie: {card.mq}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" mb={2} sx={{ gap: 1.5 }}>
                                    <LocaliIcon fillColor="#FF0000" width="20" height="20" />
                                    <Typography variant="body2" color="text.secondary">
                                        Numero locali: {card.roomQty}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" mb={2} sx={{ gap: 1.5 }}>
                                    <UltimaOfferta />
                                    <Typography variant="body2" color="text.secondary">
                                        Ultima Offerta:
                                        {card?.actualOffer?.toLocaleString("it-IT", {
                                            style: "currency",
                                            currency: "EUR",
                                        })}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" mb={2} sx={{ gap: 1.5 }}>
                                    <RilancioMinimo />
                                    <Typography variant="body2" color="text.secondary">
                                        Base d'asta:


                                        {Number(card?.actualOffer)
                                            ? Number(card?.actualOffer).toLocaleString("it-IT", {
                                                style: "currency",
                                                currency: "EUR",
                                            })
                                            : "Valore non disponibile"}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" mb={2} sx={{ gap: 1.5 }}>
                                    <RilanciTotali />
                                    <Typography variant="body2" color="text.secondary">
                                        Quantità Offerte: {card.qtyTotalBidsAmount}
                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" mb={2} sx={{ gap: 1.5 }}>
                                    <RilanciTotali />
                                    <Typography variant="body2" color="text.secondary">
                                        Valore:
                                        {Number(card?.valoreRealeImmobile)
                                            ? Number(card?.valoreRealeImmobile).toLocaleString("it-IT", {
                                                style: "currency",
                                                currency: "EUR",
                                            })
                                            : "Valore non disponibile"}


                                    </Typography>
                                </Box>
                                <Box display="flex" alignItems="center" mb={2} sx={{ gap: 1.5 }}>
                                    <RilanciTotali />
                                    <Typography variant="body2" color="text.secondary">
                                        Scadenza: {card.deadline}
                                    </Typography>
                                </Box>
                                <ColoredButton
                                    sx={{
                                        border: "1.5px solid #66C9BA",
                                        background: "#66C9BA",
                                        color: "#01685D",
                                        borderRadius: "16px",
                                        width: "100%",
                                        "&:hover": {
                                            background: "#66C9BA",
                                            border: "solid #01685D",
                                            color: "#01685D",
                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
                                        },
                                    }}
                                    size="small"
                                    onClick={() => goToRoute(card.id)}
                                >
                                    <b>Vai all'asta</b>
                                </ColoredButton>
                            </CardContent>
                        </Card>
                    </Grid>
                ))
            )}
        </Grid>
    );
};

export default AsteGrid;




