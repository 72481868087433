import React, { useState } from "react";
import { Link, Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const general = useSelector((state) => state.general);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const menuItems = [
    {
      label: "Vendi",
      link: "https://www.biddingwave.it/come-si-crea-unasta/",
      key: "Vendi",
    },
    {
      label: "Compra",
      link: "/ricerca-pubblica/",
      key: "Compra",
    },
    {
      label: "Affitta",
      link: "https://www.biddingwave.it/come-posso-aggiudicarmi-unasta",
      key: "Affitta",
    },
    {
      label: "Rivaluta",
      link: "https://www.biddingwave.it/rivaluta-il-tuo-immobile/",
      key: "Rivaluta",
    },
    {
      label: "Servizi",
      link: "https://www.biddingwave.it/Servizi",
      key: "Servizi",
    },
  ];

  const additionalItems = [
    { label: "Appartamenti", link: "/ricerca-pubblica/houseType/Appartamento" },
    {
      label: "Nuove costruzioni",
      link: "/ricerca-pubblica/newConstruction/true",
    },
    {
      label: "Vicino a me",
      link: "/ricerca-pubblica-map/near-me",
    },
    { label: "Garage", link: "/ricerca-pubblica/houseType/garage" },
    { label: "Edifici", link: "/ricerca-pubblica/houseType/edifici" },
    { label: "Uffici", link: "/ricerca-pubblica/houseType/uffici" },
    {
      label: "Locali commerciali",
      link: "/ricerca-pubblica/houseType/locali-commerciali",
    },
    { label: "Deposito", link: "/ricerca-pubblica/houseType/deposito" },
    { label: "Terreni", link: "/ricerca-pubblica/houseType/terreni" },
  ];

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <AppBar
            position="sticky"
            sx={{
              backgroundColor: "#fff",
              boxShadow: 0,
              justifyContent: "start!important",
              top: 0,
              zIndex: 1000,
            }}
          >
            <Container maxWidth="lg">
              <Toolbar sx={{}}>
                {/* Hamburger menu only on smaller screens */}
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                    marginRight: 2,
                  }}
                >
                  <IconButton
                    edge="start"
                    sx={{ color: "#6CC4B4" }}
                    onClick={handleDrawerOpen}
                  >
                    <MenuIcon />
                  </IconButton>
                </Box>
                {/* Logo */}
                <Box
                  className="logo"
                  sx={{
                    flex: 1,
                    display: { xs: "none", md: "block" },
                  }}
                >
                  <Button
                    component={RouterLink}
                    to={"/dashboard"}
                    sx={{ p: 0, "&:hover": { background: "none" } }}
                  >
                    <img
                      src="https://www.biddingwave.it/wp-content/uploads/2024/02/bidding-wave-crop.png"
                      alt="Logo"
                      style={{
                        width: "220px",
                      }}
                    />
                  </Button>
                </Box>

                {/* Menu items centered for medium and larger screens */}
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  flexGrow={1}
                  sx={{
                    display: { xs: "none", md: "flex" },
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                    flexWrap: "nowrap",
                  }}
                >
                  {menuItems.map((item) => (
                    <Grid item key={item.label} sx={{ marginX: 1 }}>
                      <Button
                        component={RouterLink}
                        to={item.link}
                        sx={{
                          color: "#000",
                          textDecoration: "none",
                          borderRadius: 0,
                          fontFamily: "Chakra Petch, sans-serif",
                          "&:hover": {
                            background: "none",
                            borderBottom: "1.5px solid #324A6D",
                          },
                        }}
                      >
                        {item.label}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
                {/* Link button on the right for all screen sizes */}

                {general.userId ? (
                  <Box sx={{ flex: 1, textAlign: "right" }}>
                    <Link to="/dashboard" style={{ textDecoration: "none" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          fontFamily: "Chakra Petch, sans-serif",
                          boxShadow: "none",
                          background: "#01685D",
                          "&:hover": {
                            background: "#66C9BA",
                            color: "#01685D",
                          },
                        }}
                      >
                        My Area
                      </Button>
                    </Link>
                  </Box>
                ) : (
                  <Box sx={{ flex: 1, textAlign: "right" }}>
                    <Link to="/" style={{ textDecoration: "none" }}>
                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          fontFamily: "Chakra Petch, sans-serif",
                          fontSize: "16px",
                          borderRadius: "3px",
                          p: "12px 24px",
                          lineHeight: 1,
                          textDecoration: "none",
                          "&:hover": {
                            textDecoration: "none",
                          },
                        }}
                      >
                        Accedi
                      </Button>
                    </Link>
                  </Box>
                )}
              </Toolbar>
            </Container>
          </AppBar>

          {/* Second AppBar for additional items */}
          <AppBar
            position="stycky"
            sx={{
              backgroundColor: "#6CC4B4",
              top: 0,
              boxShadow: 0,
              display: { xs: "none", md: "block" },
            }}
          >
            <Toolbar
              variant="string"
              sx={{
                p: 0.5,
                justifyContent: "center",
                minHeight: 0,
              }}
            >
              {additionalItems.map((item) => (
                <Button
                  key={item.label}
                  component={RouterLink}
                  to={item.link}
                  sx={{
                    color: "#fff",
                    textDecoration: "none",
                    fontFamily: "Chakra Petch, sans-serif",
                    borderBottom: "2px solid transparent",
                    marginRight: 1,
                    borderRadius: 0,
                    "&:hover": {
                      background: "none!important",
                      textDecoration: "none",
                      borderBottom: "2px solid red",
                    },
                  }}
                >
                  {item.label}
                </Button>
              ))}
            </Toolbar>
          </AppBar>

          {/* Drawer for mobile screens */}
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={handleDrawerClose}
            sx={{ width: "100%" }}
          >
            <List
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              {menuItems.map((item, index) => (
                <ListItem
                  key={`${item.label}-${index}`} // Use a combination of label and index for uniqueness
                  button={true}
                  component={RouterLink}
                  to={item.link}
                >
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
              {additionalItems.map((item, index) => (
                <ListItem
                  key={`${item.label}-${index}`} // Use a combination of label and index for uniqueness
                  button={true}
                  component={RouterLink}
                  to={item.link}
                >
                  <ListItemText primary={item.label} />
                </ListItem>
              ))}
            </List>
          </Drawer>
        </Box>
      </Box>
    </>
  );
};

export default Header;
