import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";
import {
  Box,
  Typography,
  Button,
  Drawer,
  CircularProgress,
  Backdrop,
  IconButton,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "components/Header";
import Footer from "../../components/layout/Footer";


const mapContainerStyle = {
  width: "100vw",
  height: "90vh",
};

const defaultCenter = {
  lat: 37.9762938, // Sicilia di default
  lng: 12.9606852,
};

const AstePubblicheMap = () => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [mapZoom, setMapZoom] = useState(10); // Stato per il livello di zoom della mappa
  const [userLocation, setUserLocation] = useState(null);


  const [mapCenter, setMapCenter] = useState(defaultCenter); // Stato per il centro della mappa
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("near-me")) {
      getUserLocation();
    } else {
      setMapCenter(defaultCenter);
    }
    fetchImmobili();
  }, [location.pathname]);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coords = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          setMapCenter(coords); // Centra la mappa sulla tua posizione
          setMapZoom(16); // Zoom ravvicinato
          setUserLocation(coords); // Imposta la tua posizione per il marker
        },
        (error) => {
          console.error("Errore nella geolocalizzazione:", error);
          setMapCenter(defaultCenter); // Fallback alla Sicilia
          setMapZoom(10);
        }
      );
    } else {
      console.warn("Geolocalizzazione non supportata.");
      setMapCenter(defaultCenter);
      setMapZoom(10);
    }
  };

  const fetchImmobili = async () => {
    setLoading(true);
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const response = await fetch(
        `${process.env.REACT_APP_APP_ENDPOINT}api/getRecordsView/immobile`,
        { headers }
      );
      const data = await response.json();
      setCards(data);
    } catch (error) {
      console.error("Errore nel recupero degli immobili:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkerClick = (immobile) => {
    setSelectedMarker(immobile);
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedMarker(null);
  };

  const images = (immobile) => {
    const imageFields = [
      "firstImage",
      "secondImage",
      "thirdImage",
      "fourthImage",
      "fifthImage",
    ];
    return imageFields.map((field) => immobile[field]).filter((url) => url);
  };

  return (
    <>
      <Header />
      {/* Overlay e Loader */}
      {loading && (
        <Backdrop
          open={loading}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      <LoadScript googleMapsApiKey="AIzaSyCbhDlD07SL8tNmJInHzd82QhUmiUSxrfA">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={mapCenter}
          zoom={mapZoom} // Imposta dinamicamente il livello di zoom
          options={{
            zoomControl: true, // Abilita i controlli dello zoom
            streetViewControl: true, // Abilita il controllo Street View
            mapTypeControl: true, // Mostra il controllo per cambiare tipo di mappa
            fullscreenControl: true, // Abilita il controllo Fullscreen
          }}
        >
          {userLocation && (
            <Marker
              position={userLocation}
              label={{
                text: "Ti trovi qui",
                color: "green",
                fontWeight: "bold",
                fontSize: "14px",
              }}
              icon={{
                url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
              }}
            />
          )}
          {cards.map((immobile) => (
            <Marker
              key={immobile.id}
              position={{
                lat: parseFloat(immobile.latitude),
                lng: parseFloat(immobile.longitude),
              }}
              onClick={() => handleMarkerClick(immobile)}
            />
          ))}
        </GoogleMap>
      </LoadScript>

      {/* Drawer */}
      <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
        {selectedMarker && (
          <Box sx={{ width: 350, padding: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h6" fontWeight="bold">
                {selectedMarker.title}
              </Typography>
              <IconButton onClick={closeDrawer}>
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Slider immagini */}
            <Box sx={{ my: 2 }}>
              <Slider dots infinite autoplay>
                {images(selectedMarker).map((img, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={img}
                    alt={`Immagine ${index + 1}`}
                    sx={{ width: "100%", height: 200, objectFit: "cover" }}
                  />
                ))}
              </Slider>
            </Box>

            <Typography variant="body1" sx={{ mb: 2 }}>
              {selectedMarker.shortDescription}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => navigate(`/asta/${selectedMarker.id}`)}
            >
              Visualizza
            </Button>
          </Box>
        )}
      </Drawer>

    </>
  );
};

export default AstePubblicheMap;
