import React, { useState, useEffect, useRef } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Typography,
    TextField,
    Button,
    Skeleton,
    Grid,
    Paper,
    FormControlLabel,
    Checkbox,
    Select,
    MenuItem,
    Box,
} from "@mui/material";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import { styled } from "styled-components";
import axios from "axios";


const ApplyButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#94d9cf",
    color: "#0d7267",
    borderRadius: "20px",
    padding: "10px 0",
    "&:hover": {
        backgroundColor: "transparent",
        color: "#66c9ba",
    },
}));

const ResetButton = styled(Button)(({ theme }) => ({
    backgroundColor: "red",
    color: "#94d9cf",
    borderRadius: "20px",
    padding: "10px 0",
    "&:hover": {
        backgroundColor: "transparent",
        color: "#66c9ba",
    },
}));

const SidebarFilters = ({
    filterConfigs,
    selectedFilters,
    handleFilterChange,
    handleUpdateClick,
    handleResetFilters,
    loading,
}) => {
    const [params, setParams] = useState({});
    const [loader, setLoader] = useState(false);

    const [lookupOptions, setLookupOptions] = useState({});
    const location = useLocation();
    const autocompleteRef = useRef(null); // Definizione di autocompleteRef
    const [filters, setFilters] = useState({}); // Stato locale per i filtri

    useEffect(() => {
        fetchLookupOptions();
    }, [filterConfigs]);

    const fetchLookupOptions = async () => {
        filterConfigs.forEach(async (filterConfig) => {
            if (
                filterConfig.filterType === "lookup" ||
                filterConfig.filterType === "multiplelookup"
            ) {
                try {
                    const headers = {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    };
                    const response = await axios.get(
                        process.env.REACT_APP_APP_ENDPOINT +
                        `api/getRecordsView/${filterConfig.relatedObject}`,
                        { headers: headers }
                    );
                    // Mappa l'array di risposta per creare un formato adatto al componente Select
                    const options = response.data.map((item) => ({
                        value: item.id, // Utilizza 'id' come valore
                        label: item.title, // Utilizza 'title' come etichetta
                    }));
                    setLookupOptions((prev) => ({
                        ...prev,
                        [filterConfig.fieldName]: options,
                    }));
                } catch (error) {
                    console.error("Errore nel recupero delle opzioni di lookup:", error);
                }
            }
        });
    };

    function generateRangeOptions(min, max, step = 1) {
        const options = [];
        for (let i = min; i <= max; i += step) {
            options.push(i);
        }
        return options;
    }

    useEffect(() => {
        setLoader(true);
        const pathSegments = location.pathname
            .replace("/ricerca-pubblica/", "")
            .split("/")
            .filter((segment) => segment);

        const parsedParams = {};
        for (let i = 0; i < pathSegments.length; i += 2) {
            const key = pathSegments[i];
            const value = pathSegments[i + 1];
            if (key && value) {
                parsedParams[key] = decodeURIComponent(value);
            }
        }

        // Inizializza i filtri con i valori dei parametri URL
        const initialFilters = {};
        filterConfigs.forEach((filterConfig) => {
            const paramValue = parsedParams[filterConfig.fieldName];
            initialFilters[filterConfig.fieldName] = paramValue || "";
        });

        setFilters(initialFilters); // Imposta lo stato locale dei filtri
        setParams(parsedParams);
        setLoader(false);
    }, [location.pathname, filterConfigs]);


    const handlePlaceChange = (fieldName) => {
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();
            if (place) {
                const city = place.address_components.find((component) =>
                    component.types.includes("locality")
                )?.long_name;
                if (city) {
                    handleFilterChange(fieldName, city);
                }
            }
        }
    };


    const generateFilterComponent = ({ filterConfig }) => {
        const fieldValue = filters[filterConfig.fieldName] || ""; // Valore corrente per il filtro


        switch (filterConfig.filterType) {
            case "lookup":
                const lookupOptionsList = lookupOptions[filterConfig.fieldName] || [];

                // Normalizza la label e il fieldValue rimuovendo i trattini e rendendoli lowercase
                const normalizeString = (str) => str?.toLowerCase().replace(/-/g, " ");

                const matchedOption = lookupOptionsList.find(
                    (option) =>
                        normalizeString(option.label) === normalizeString(fieldValue)
                );

                const resolvedValue = matchedOption ? matchedOption.value : ""; // Ottieni il valore numerico

                return lookupOptionsList.length > 0 ? (
                    <Select
                        defaultValue={resolvedValue}
                        onChange={(e) =>
                            handleFilterChange(filterConfig.fieldName, e.target.value)
                        }
                        displayEmpty
                        sx={{ width: "100%", borderRadius: "8px" }}
                    >
                        <MenuItem key="default" value="">
                            Seleziona
                        </MenuItem>
                        {lookupOptionsList.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                ) : (
                    <Skeleton variant="rectangular" width="100%" height={40} />
                );

            case "multiplelookup":
                const multipleLookupOptions =
                    lookupOptions[filterConfig.fieldName] || [];
                return (
                    <Select
                        multiple
                        defaultValue={selectedFilters[filterConfig.fieldName] || []}
                        onChange={(e) => {
                            // Gestisce l'aggiornamento per selezioni multiple
                            const { value } = e.target;
                            handleFilterChange(filterConfig.fieldName, value);
                        }}
                        renderValue={(selected) =>
                            selected
                                .map(
                                    (val) =>
                                        multipleLookupOptions.find((option) => option.value === val)
                                            ?.label || val
                                )
                                .join(", ")
                        }
                        displayEmpty
                        sx={{ width: "100%", borderRadius: "8px" }}
                    >
                        <MenuItem disabled value="">
                            <em>Seleziona</em>
                        </MenuItem>
                        {multipleLookupOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                <Checkbox
                                    checked={
                                        selectedFilters[filterConfig.fieldName]?.indexOf(
                                            option.value
                                        ) > -1
                                    }
                                />
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                );
            case "autocompleteGoogle":
                return (
                    <LoadScript googleMapsApiKey="AIzaSyCbhDlD07SL8tNmJInHzd82QhUmiUSxrfA" libraries={["places"]}>
                        <Autocomplete
                            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                            onPlaceChanged={() => handlePlaceChange(filterConfig.fieldName)}
                        >
                            <TextField
                                fullWidth
                                label={filterConfig.label || "Seleziona una città"}
                                variant="outlined"
                                defaultValue={fieldValue}
                                onChange={(e) => handleFilterChange(filterConfig.fieldName, e.target.value)}
                            />
                        </Autocomplete>
                    </LoadScript>
                );

            case "select":
                const options = filterConfig.options?.split(",") || [];
                const selectedValue = filters[filterConfig.fieldName]; // Ottieni il valore selezionato

                return (
                    <Select
                        value={selectedFilters[filterConfig.fieldName] || ""}
                        onChange={(e) =>
                            handleFilterChange(filterConfig.fieldName, e.target.value)
                        }
                        displayEmpty
                        sx={{ width: "100%", borderRadius: "8px" }}
                    >
                        <MenuItem key="default" value="">
                            Seleziona
                        </MenuItem>

                        {
                            options.map((option, index) => (
                                <MenuItem key={index} value={option.trim()}>
                                    {option.trim()}
                                </MenuItem>
                            ))
                        }
                    </Select >
                );

            case "boolean":
                return (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!selectedFilters[filterConfig.fieldName]} // Usa filters

                                onChange={(e) =>
                                    handleFilterChange(filterConfig.fieldName, e.target.checked)
                                }
                                name={filterConfig.fieldName}
                                color="primary"
                            />
                        }
                        label={filterConfig.label || ""}
                    />
                );
            case "range":
                const minOptions = generateRangeOptions(
                    filterConfig.min || 0,
                    filterConfig.max || 2000,
                    filterConfig.step !== undefined ? filterConfig.step : 50
                );
                const maxOptions = generateRangeOptions(
                    filterConfig.min || 0,
                    filterConfig.max || 2000,
                    filterConfig.step !== undefined ? filterConfig.step : 50
                );

                return (
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Select
                                defaultValue={selectedFilters[filterConfig.fieldName]?.min || ""}
                                onChange={(e) =>
                                    handleFilterChange(filterConfig.fieldName, {
                                        ...selectedFilters[filterConfig.fieldName],
                                        min: e.target.value,
                                    })
                                }
                                displayEmpty
                                sx={{
                                    width: "100%",
                                    borderRadius: "8px",
                                }}
                            >
                                <MenuItem key="empty" value="" selected>
                                    Seleziona un'opzione...
                                </MenuItem>
                                {minOptions.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={6}>
                            <Select
                                defaultValue={selectedFilters[filterConfig.fieldName]?.max || ""}
                                onChange={(e) =>
                                    handleFilterChange(filterConfig.fieldName, {
                                        ...selectedFilters[filterConfig.fieldName],
                                        max: e.target.value,
                                    })
                                }
                                displayEmpty
                                sx={{ width: "100%", borderRadius: "8px" }}
                            >
                                <MenuItem key="empty" value="" selected>
                                    Seleziona un'opzione...
                                </MenuItem>
                                {minOptions.map((option, index) => (
                                    <MenuItem key={index} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                );
            default:
                return (
                    <TextField
                        defaultValue={selectedFilters[filterConfig.fieldName] || ""}
                        onChange={(e) =>
                            handleFilterChange(filterConfig.fieldName, e.target.value)
                        }
                        label={filterConfig.label || ""}
                        fullWidth
                    />
                );
        }
    };

    return (
        <>

            <Card sx={{ borderRadius: "15px" }}>
                <CardHeader
                    title={
                        <Typography
                            variant="h6"
                            sx={{ color: "#01685d", fontWeight: "700" }}
                        >
                            FILTRA LE ASTE DISPONIBILI
                        </Typography>
                    }
                />
                <CardContent sx={{ py: 0 }}>
                    {loader ? (
                        <Skeleton />
                    ) : (
                        <>
                            {filterConfigs.map((filterConfig, index) => (
                                <Box key={index} mb={3}>
                                    <Typography variant="subtitle1" fontWeight="700">
                                        {filterConfig.label}
                                    </Typography>
                                    {generateFilterComponent({ filterConfig })}
                                </Box>
                            ))}
                            <Box display="flex" justifyContent="center">
                                <ApplyButton onClick={handleUpdateClick}>FILTRA</ApplyButton>
                                <ResetButton onClick={handleResetFilters} sx={{ ml: 2 }}>
                                    RESET
                                </ResetButton>
                            </Box>
                        </>
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default SidebarFilters;
