import React, { useState, useEffect } from "react";
import { Box, Grid, Container, Drawer, useMediaQuery, Backdrop, CircularProgress, Skeleton } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import Header from "components/Header";
import SidebarFilters from "./SidebarFilters";
import AsteGrid from "./AsteGrid";
const AstePubbliche = () => {
  const [cards, setCards] = useState([]);
  const [filterConfigs, setFilterConfigs] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [lookupOptions, setLookupOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingGrid, setLoadingGrid] = useState(false);
  const [filtersReady, setFiltersReady] = useState(false); // Nuovo stato
  const isMobileOrTablet = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const [filters, setFilters] = useState({}); // Stato locale per i filtri

  const params = useParams();
  const location = useLocation()
  useEffect(() => {
    const init = async () => {
      setLoading(true);

      // 1. Carica le configurazioni dei filtri
      const loadedFilterConfigs = await fetchFilterConfigs("immobile");

      // 2. Carica le lookup options e aspetta il loro completamento
      const updatedLookupOptions = await fetchLookupOptions(loadedFilterConfigs);

      // 3. Chiama applyParamsToFilters con lookupOptions aggiornate
      const payload = applyParamsToFilters(loadedFilterConfigs, updatedLookupOptions);

      console.log("Payload finale:", payload);

      // 4. Carica i dati con il payload
      await fetchData(payload);

      setFiltersReady(true);
      setLoading(false);
    };


    init();
  }, [location]);

  const fetchFilterConfigs = async (objectName) => {
    try {
      const headers = { Authorization: "Bearer " + localStorage.getItem("token") };
      const response = await axios.get(
        `${process.env.REACT_APP_APP_ENDPOINT}api/getFieldsForObjectForm/${objectName}`,
        { headers }
      );

      let filtersForObject = [];
      for (const key in response.data) {
        if (response.data.hasOwnProperty(key)) {
          const fields = response.data[key];
          const filteredFields = fields
            .filter((field) => field.isFiltrable)
            .map((field) => ({ ...field, objectName }));
          filtersForObject = filtersForObject.concat(filteredFields);
        }
      }

      setFilterConfigs(filtersForObject);
      return filtersForObject; // Restituisci i filtri caricati
    } catch (error) {
      console.error("Errore nel recupero dei filtri:", error);
      return [];
    }
  };

  const fetchLookupOptions = async (filterConfigs) => {
    const promises = filterConfigs
      .filter((config) => config.filterType === "lookup" || config.filterType === "multiplelookup")
      .map(async (config) => {
        try {
          const headers = { Authorization: "Bearer " + localStorage.getItem("token") };
          const response = await axios.get(
            `${process.env.REACT_APP_APP_ENDPOINT}api/getRecordsView/${config.relatedObject}`,
            { headers }
          );
          return { fieldName: config.fieldName, options: response.data };
        } catch {
          return { fieldName: config.fieldName, options: [] };
        }
      });

    const results = await Promise.all(promises);
    const newLookupOptions = results.reduce((acc, result) => {
      acc[result.fieldName] = result.options;
      return acc;
    }, {});

    setLookupOptions(newLookupOptions);
    return newLookupOptions; // Restituisce lookupOptions
  };


  const applyParamsToFilters = (filterConfigs, lookupOptions) => {
    const updatedFilters = {};

    const pathSegments = location.pathname
      .replace("/ricerca-pubblica/", "")
      .split("/")
      .filter((segment) => segment);

    for (let i = 0; i < pathSegments.length; i += 2) {
      const key = pathSegments[i];
      const value = pathSegments[i + 1];

      if (key && value) {
        const filterConfig = filterConfigs.find((config) => config.fieldName === key);

        if (filterConfig) {
          // Mappa il valore tramite lookupOptions per ottenere l'ID
          if (filterConfig.filterType === "lookup" && lookupOptions[key]) {
            const option = lookupOptions[key]?.find(
              (opt) =>
                opt.title && // Usa title invece di label
                opt.title.toLowerCase() === decodeURIComponent(value).toLowerCase().replace(/-/g, " ")
            );

            if (option) {
              updatedFilters[key] = option.id; // Imposta l'ID
            }
          } else {
            const decodedValue = decodeURIComponent(value);
            if (decodedValue === "true") {
              updatedFilters[key] = true;
            } else if (decodedValue === "false") {
              updatedFilters[key] = false;
            } else {
              updatedFilters[key] = decodedValue;
            }

          }
        }
      }
    }

    // Costruisci il payload finale
    const payload = {
      immobile: {
        status: ["Attiva", "Conclusa", "Aggiudicata"],
        ...updatedFilters,
      },
    };

    setSelectedFilters(updatedFilters);
    return payload;
  };

  const goToRoute = (id) => {
    navigate(`/asta/${id}`);
  };


  const fetchData = async (filters) => {
    setLoadingGrid(true);
    try {
      const headers = { Authorization: "Bearer " + localStorage.getItem("token") };

      const response = await axios.get(
        `${process.env.REACT_APP_APP_ENDPOINT}api/getRecordsView/immobile`,
        { headers, params: { filterPayload: JSON.stringify(filters) } } // Invio diretto
      );
      setCards(response.data);
    } catch (error) {
      console.error("Errore nel recupero dei dati:", error);
    } finally {
      setLoadingGrid(false);
    }
  };
  const handleFilterChange = (filterName, value) => {
    // Aggiorna selectedFilters
    setSelectedFilters((prev) => {
      if (filterName === "newConstruction" && (value === false || value === "false")) {
        const { [filterName]: _, ...remaining } = prev;
        return remaining;
      }

      if (value === "" || value == null) {
        const { [filterName]: _, ...remainingFilters } = prev;
        return remainingFilters;
      } else {
        return {
          ...prev,
          [filterName]: value,
        };
      }
    });

    // Aggiorna filters
    setFilters((prev) => {
      if (filterName === "newConstruction" && (value === false || value === "false")) {
        const { [filterName]: _, ...remainingFilters } = prev;
        return remainingFilters;
      }

      const newFilters = value
        ? { ...prev, [filterName]: value }
        : Object.keys(prev).reduce((acc, key) => {
          if (key !== filterName) {
            acc[key] = prev[key];
          }
          return acc;
        }, {});

      return newFilters;
    });
  };



  const handleUpdateClick = () => {
    const defaultStatus = ["Attiva", "Conclusa", "Aggiudicata"];

    const combinedFilters = {
      immobile: {
        status: defaultStatus,
        ...selectedFilters,
        ...filters,
      },
    };
    fetchData(combinedFilters);
  };

  const handleResetFilters = () => {
    navigate("/ricerca-pubblica");
  };

  return (
    <>
      <Header />
      <Container maxWidth>
        <Grid container spacing={1} sx={{ px: 4, py: 4 }}>
          {/* Sidebar con i filtri */}
          <Grid item xs={12} sm={12} md={3}>
            {loading || filterConfigs.length === 0 ? (
              // Skeleton per la sidebar durante il caricamento
              <Box sx={{ marginRight: 3 }}>
                <Skeleton variant="rectangular" height={60} sx={{ mb: 2, borderRadius: "8px" }} />
                <Skeleton variant="rectangular" height={60} sx={{ mb: 2, borderRadius: "8px" }} />
                <Skeleton variant="rectangular" height={60} sx={{ mb: 2, borderRadius: "8px" }} />
                <Skeleton variant="rectangular" height={60} sx={{ mb: 2, borderRadius: "8px" }} />
                <Skeleton variant="rectangular" height={60} sx={{ mb: 2, borderRadius: "8px" }} />
              </Box>
            ) : (
              // Sidebar quando il caricamento è terminato
              <SidebarFilters
                filterConfigs={filterConfigs}
                selectedFilters={selectedFilters}
                lookupOptions={lookupOptions}
                handleFilterChange={handleFilterChange}
                handleUpdateClick={handleUpdateClick}
                handleResetFilters={handleResetFilters}
                isMobileOrTablet={isMobileOrTablet}
              />
            )}
          </Grid>


          {/* Griglia con le aste */}
          <Grid item xs={12} sm={9}>
            {filtersReady ? (
              loadingGrid ? (
                <Grid container spacing={2}>
                  {Array.from(new Array(6)).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      <Box sx={{ boxShadow: 1, borderRadius: "10px" }}>
                        <Skeleton variant="rectangular" height={180} sx={{ borderRadius: "10px 10px 0 0" }} />
                        <Box sx={{ p: 2 }}>
                          <Skeleton variant="text" width="60%" height={30} />
                          <Skeleton variant="text" width="40%" height={20} />
                          <Skeleton variant="text" width="80%" height={20} />
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <AsteGrid cards={cards} loadingCard={loadingGrid} goToRoute={goToRoute} />
              )
            ) : (
              <Grid container spacing={2}>
                {Array.from(new Array(6)).map((_, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box sx={{ boxShadow: 1, borderRadius: "10px" }}>
                      <Skeleton variant="rectangular" height={180} sx={{ borderRadius: "10px 10px 0 0" }} />
                      <Box sx={{ p: 2 }}>
                        <Skeleton variant="text" width="60%" height={30} />
                        <Skeleton variant="text" width="40%" height={20} />
                        <Skeleton variant="text" width="80%" height={20} />
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>

      {/* Loader Centrale con Overlay */}
      <Backdrop open={loading || loadingGrid} sx={{ color: "#fff", zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </>
  );
};

export default AstePubbliche;
